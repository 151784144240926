export const removeTag = (content) => {
  const REGEX = /<("[^"]*"|'[^']*'|[^'">])*>/g
  const removeLinkContent = content.replace(REGEX, "")
  return removeLinkContent 
}


export const removeCode = (content) => {
  const REGEX = /<code\b[^<]*(?:(?!<\/code>)<[^<]*)*<\/code>/g;
  const removeBrContent = content.replace(REGEX,"");
  return removeBrContent
}

export const textParse = (content) => {
  const noCodeText = removeCode(content);
  const noTagText = removeTag(noCodeText);
  return noTagText
}