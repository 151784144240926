import React, {useState, useEffect} from "react";
import styled from '@emotion/styled'

export const SpeechButton = ({content}) => {
  const [rate, setRate] = useState(1.5)
  const uttr = new SpeechSynthesisUtterance(content)
  uttr.rate = rate;
  const [isStart, startSpeech] = useIsBoolean({
    trueFunc: () => speechSynthesis.cancel(),
    falseFunc: () => speechSynthesis.speak(uttr),
  })
  const [isStop, stopSpeech] = useIsBoolean({
    trueFunc: () => speechSynthesis.resume(),
    falseFunc: () => speechSynthesis.pause(),
  }) 

  const onRateChange = (e) => {
    const rate = e.target.value;
    setRate(rate);
  }

  useEffect(() => {
    uttr.rate = rate;
    return () => {
      speechSynthesis.cancel();
    }
  }, [rate])

  const startText = isStart ? '終了' : '読み上げ'
  const pauseText = isStop ? '再開' : 'ストップ'

  const stopButton = isStart && <Button style={{backgroundColor: 'red'}}onClick={stopSpeech}>{pauseText}</Button>
  const changeRate = !isStart && (
    <>
    <label>Speed</label>
    <input
      type="number"
      min="0.1"
      max="5"
      step="0.1"
      value={rate}
      onChange={onRateChange}
    ></input>
    <Input
      type="range"
      min="0.1"
      max="5"
      step="0.1"
      value={rate}
      onChange={onRateChange}
    ></Input>
    </>
  )
  if (!('speechSynthesis' in window)) return

  return (
    <div>
      <Button style={{backgroundColor: 'blue'}} onClick={startSpeech}>{startText}</Button>
      {stopButton}
      {changeRate}
    </div>
  )
};


export const useIsBoolean = ({defaultBool = false, trueFunc, falseFunc}) => {
  const [bool, setBoolean] = useState(defaultBool);
  const onClick = () => {
    setBoolean(bool => !bool);
    if (bool) {
      trueFunc();
      return
    }
    falseFunc()
  }
  return [bool, onClick]
};


const Button = styled.button`
  color: #fff;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 3px 1px #666;
  -moz-box-shadow: 2px 2px 3px 1px #666;
  -webkit-box-shadow: 2px 2px 3px 1px #666;
  margin-bottom: 2rem;
  margin-right: 0.6rem;
`
const Input = styled.input`
  background-color: #c7c7c7;
  height: 3.5px;
  width: 6rem;
`