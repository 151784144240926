import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PocketShareButton,
  PocketIcon,
  HatenaShareButton,
  HatenaIcon,
} from 'react-share';

export const SNSButtons = ({location, title}) => {
  const shareUrl = location.href
  return (
    <>
      <FacebookShareButton {...{title}} url={shareUrl} className="mr-2">
        <FacebookIcon size={40} round />
      </FacebookShareButton>
      <TwitterShareButton {...{ title }} url={shareUrl} className="mr-2">
        <TwitterIcon size={40} round />
      </TwitterShareButton>
      <HatenaShareButton {...{ title }} url={shareUrl} className="mr-2">
        <HatenaIcon size={40} round />
      </HatenaShareButton>
    </>
  )
};